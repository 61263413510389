import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import { RootStackParamList } from "./navigation.types";

/**
 * Linking configuration.
 * See: https://reactnavigation.org/docs/configuring-links
 * and: https://docs.expo.dev/versions/latest/sdk/notifications/#handling-push-notifications-with-react-navigation
 */
export const linkingOptions: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL("/"), "asc-team://"],
  config: {
    initialRouteName: "MainTabs",
    screens: {
      MainTabs: {
        initialRouteName: "Home",
        path: "",
        screens: {
          Home: {
            /**
             * Specifying an id automatically opens a message modal for the
             * message with the given id.
             * While the modal can be opened from anywhere, routing to home
             * instead of directly to the modal makes the app switch to the
             * home tab before opening the modal.
             */
            path: "home/:id?",
            parse: {
              id: Number,
            },
          },
          General: "general",
          Personal: "personal",
          PersonalVideo: "personalVideo",
          Settings: "settings",
        },
      },
      Messages: "messages",
      MessageModal: "message",
      ComposeModal: "compose",
      FilePreview: "file/:id?",
      // TODO?: add 404 screen for bad urls.
      // E.g. NotFound: "*"
      // With NotFound being a screen in RootStack.
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    const notificationUrl = response?.notification.request.content.data.url;
    if (notificationUrl && typeof notificationUrl === "string") {
      return notificationUrl;
    }

    return;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    Linking.addEventListener("url", onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const url = response.notification.request.content.data.url;

      // Any custom logic to see whether the URL needs to be handled
      //...
      console.log("Notification url:", url);

      // Let React Navigation handle the URL
      if (typeof url === "string") {
        listener(url);
      }
    });

    return () => {
      // Clean up the event listeners
      Linking.removeEventListener("url", onReceiveURL);
      subscription.remove();
    };
  },
};
