import axios, { AxiosRequestConfig } from "axios";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import { useQuery } from "react-query";
import { Team } from "../models/Team";
import { Auth } from "./auth.service";
import { backendEnvironment } from "./backend.service";
import { Message } from "../models/Message";

/**
 *
 * @returns
 */
const getMessages = async () => {
  const headers = await Auth.header();
  return await axios
    .get<Message[]>(backendEnvironment.address() + "messages", {
      withCredentials: true,
      headers,
    })
    .then((value) => {
      // Make date objects if not already present.
      value.data.forEach((m: any) => {
        m.created ?? (m.created = new Date(m._created ?? 0));
      });
      return value;
    });
};

/**
 *
 * @param data should probably be { username, password } depending on backend
 */
const postMessage = async (data: any) => {
  const headers = await Auth.header();
  return await axios.post(backendEnvironment.address() + "message", data, {
    withCredentials: true,
    headers,
  });
};

/**
 *
 * @returns
 */
export const useMessages = () => {
  const queryMessages = useQuery(
    "messages",
    async () => {
      const user = await getMessages();
      return user;
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  // When we're loading data for the first time
  if (queryMessages.data === undefined) return { queryMessages, messages: null, online: false };
  const messages = queryMessages.data ? queryMessages.data.data : null;

  return { queryMessages, messages };
};

/**
 *
 * @param team
 * @param subject
 * @param body
 */
const sendMessage = async (team: Team, subject: string, body: string) => {
  let {
    data,
    status,
    statusText,
    headers,
    config,
    request,
  }: {
    data: string;
    status: number;
    statusText: string;
    headers: any;
    config: AxiosRequestConfig;
    request?: any;
  } = await postMessage({ team, subject, body });

  // TODO: Handle response and error
};

/**
 *
 * @returns
 */
const registerForPushNotificationsAsync = async () => {
  let token;

  if (Platform.OS !== "web") {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    console.log({ existingStatus });
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
      console.log({ status });
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    //token = '';
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
};

export const MessageService = { registerForPushNotificationsAsync, sendMessage };
