import React, { useEffect } from "react";
import { View } from "react-native";
import TextInput from "./TextInput";
import { StyleContext } from "../style/StyleSheet";
import { useMe } from "../services/user.service";
import Button from "./Button";
import { User } from "../models/User";
import TextHeading from "./TextHeading";

type Props = {
  /** For admins to change info of other users (TODO) */
  user?: User;
};

const UserInfoCard = (props: Props) => {
  const style = React.useContext(StyleContext);
  const [editing, setEditing] = React.useState(false);
  const { me } = useMe();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("12312312");
  const [email, setEmail] = React.useState("");

  // Sets the text inputs' values.
  useEffect(() => {
    // Ignore if the user is currently editing.
    if (me && !editing) {
      setFirstName(me.firstname);
      setLastName(me.lastname);
      setEmail(me.email);
      console.log("EFFECT RUN");
    }
  }, [me?.firstname, me?.lastname, me?.email]);

  return (
    <View style={[style.card, { margin: 10, padding: 5 }]}>
      <View style={{ flexDirection: "row" }}>
        <TextHeading style={{ flex: 1 }}>Profile</TextHeading>
        {editing ? (
          <>
            <Button
              text="Cancel"
              type="text"
              onPress={() => {
                setEditing(false);
                setFirstName(me?.firstname ?? "");
                setLastName(me?.lastname ?? "");
              }}
              textStyle={{ fontSize: 20 }}
            />
            <Button
              text="Save"
              onPress={() => {
                console.log("Saving changes");
                // TODO: Send changes to backend
                const success = true; // if operation succeeded.
                if (success) {
                  setEditing(false);
                } else {
                  // Tell using to try again or something.
                }
              }}
              type="text"
              textStyle={{ fontSize: 20 }}
            />
          </>
        ) : (
          <Button
            text="Edit"
            type="text"
            onPress={() => {
              setEditing(true);
            }}
            textStyle={{ fontSize: 20 }}
          />
        )}
      </View>
      <TextInput
        value={firstName}
        onChangeText={(text) => setFirstName(text)}
        type={"border"}
        editable={editing}
        label="First Name"
      />
      <TextInput
        value={lastName}
        onChangeText={(text) => setLastName(text)}
        type={"border"}
        editable={editing}
        label="Last Name"
      />
      <TextInput
        value={phoneNumber}
        onChangeText={(text) => setPhoneNumber(text)}
        type={"border"}
        editable={editing}
        label="Phone Number"
      />
      <TextInput value={email} type={"border"} editable={false} label="Email" />
    </View>
  );
};

export default UserInfoCard;
