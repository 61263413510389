import React from "react";
import {
  Text,
  TextStyle,
  StyleProp,
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps,
} from "react-native";
import { StyleContext } from "../style/StyleSheet";

type Props = {
  type?: "clear" | "border";
  style?: StyleProp<TextStyle>;
  label?: string;
} & TextInputProps;

export type TextInputRef = RNTextInput;

/**
 * An extended and styled React Native TextInput component with an optional label.
 *
 * @param props component props
 * @returns A text input element
 */
const TextInput = React.forwardRef<TextInputRef, Props>((props, ref) => {
  const styleSheet = React.useContext(StyleContext);
  const { type = "clear", style, label, onFocus, onBlur, ...rest } = props;
  const [focused, setFocused] = React.useState(false);

  return (
    <>
      {label && (
        <Text style={[styles.label, focused ? { color: styleSheet.colors.tint } : null]}>
          {label}
        </Text>
      )}
      <RNTextInput
        style={[
          styleSheet.textInput,
          { borderWidth: type === "border" ? 2 : 0 },
          props.editable ? null : styles.readonly,
          focused ? null : styles.blurred,
          style,
        ]}
        onFocus={(e) => {
          setFocused(true);
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur && onBlur(e);
        }}
        ref={ref}
        {...rest}
      />
    </>
  );
});

const styles = StyleSheet.create({
  blurred: {
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  readonly: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  label: {
    fontSize: 12,
    color: "rgb(127, 127, 127)",
    marginTop: 5,
    marginHorizontal: 5,
  },
});

export default TextInput;
