import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import RootNavigator from "./src/navigation/RootNavigator";
import { SignInScreen } from "./src/screens";
import { AuthProvider, useAuthContext } from "./src/Authentication";
import { createLightTheme } from "./src/style/Theme";
import { createThemedStyleSheet, StyleContext } from "./src/style/StyleSheet";
import { ImageBackground, Platform } from "react-native";
import { MessageService } from "./src/services/message.service";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toast } from "./src/components";
import { navigationRef, navigate } from "./src/navigation/navigation.types";
import { linkingOptions } from "./src/navigation/linking";
import { DrawerNavigator } from "./src/navigation/DrawerNavigator";

// Hides warnings from react-query related to a react-native issue (fixed in RN 0.65).
// https://github.com/tannerlinsley/react-query/issues/1259
// https://github.com/facebook/react-native/issues/12981
// LogBox.ignoreLogs(["Setting a timer for a long period of time"]);

const defaultTheme = createLightTheme("#222222");
const defaultStyle = createThemedStyleSheet(defaultTheme);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 5 * 60 * 1000, refetchOnWindowFocus: false },
  },
});

export const App = () => {
  // Notification initialization
  const [expoPushToken, setExpoPushToken] = React.useState("");

  // Notification effects.
  React.useEffect(() => {
    if (Platform.OS !== "web") {
      // Incoming notifications when the app is foregrounded is handled in RootNavigator.
      // Incoming notifications when the app is not foregrounded is handled in linking
      // configuration (src/navigation/linking.ts).
      MessageService.registerForPushNotificationsAsync().then((token) => {
        setExpoPushToken(token ? token : "");
        console.log(token);
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider>
        <AuthProvider>
          <Content />
        </AuthProvider>
      </SafeAreaProvider>
    </QueryClientProvider>
  );
};

/** Content wrapped inside AuthProvider */
export const Content = () => {
  const [navTheme, setNavTheme] = React.useState(defaultTheme);
  const [styleSheet, setStyleSheet] = React.useState(defaultStyle);

  const authContext = useAuthContext();

  // Sets the active team when the auth user changes. Probably only on login/logout.
  React.useEffect(() => {
    if (authContext.user && authContext.user.teams && !authContext.activeTeam) {
      console.log("[App.tsx:Content] Setting active team");
      authContext.setActiveTeam(authContext.user.teams[0]);
    }
  }, [authContext.user]);

  // Update the theme and style sheet when the active team changes.
  React.useEffect(() => {
    if (authContext.activeTeam) {
      console.log("[App.tsx:Content] Updating theme to match new team");
      let newTheme = createLightTheme(authContext.activeTeam.backgroundColor);
      setNavTheme(newTheme);
      setStyleSheet(createThemedStyleSheet(newTheme));
    } else {
      console.log("[App.tsx:Content] Setting theme default");
      setNavTheme(defaultTheme);
      setStyleSheet(defaultStyle);
    }
  }, [authContext.activeTeam]);

  // Loading....
  if (!authContext || authContext.user === undefined) return <></>;

  return (
    <StyleContext.Provider value={styleSheet}>
      {!authContext || !authContext.user ? (
        // The user isn't signed in.
        <SignInScreen />
      ) : (
        // The user is signed in.
        <NavigationContainer theme={navTheme} ref={navigationRef} linking={linkingOptions}>
          <DrawerNavigator />
          <Toast.Toast />
        </NavigationContainer>
      )}
    </StyleContext.Provider>
  );
};

export default App;
