// storage.js
import AsyncStorage from "@react-native-async-storage/async-storage";

/**
 *
 * @param key
 * @returns
 */
function getItem(key: string) {
  return AsyncStorage.getItem(key);
}

/**
 *
 * @param key
 * @param value
 * @returns
 */
function setItem(key: string, value: string) {
  return AsyncStorage.setItem(key, value);
}

/**
 *
 * @param key
 * @returns
 */
function deleteItem(key: string) {
  return AsyncStorage.removeItem(key);
}

export const Storage = { getItem, setItem, deleteItem };
