import axios, { AxiosRequestConfig } from "axios";
import { useQuery } from "react-query";
import { User } from "../models/User";
import { Auth } from "./auth.service";
import { backendEnvironment } from "./backend.service";

/**
 * Requests the logged in user's info.
 */
const requestMe = async () => {
  const headers = await Auth.header();
  return axios.get<User>(backendEnvironment.address() + "me", {
    headers,
    withCredentials: true,
  });
};

const getMe = async () => {
  const expireInMinutes = await Auth.expireInMinutes();
  //console.log({ expireInMinutes })
  if (expireInMinutes < 0) {
    return null;
  }

  try {
    let { data, status, statusText, headers, config, request } = await requestMe();

    // console.log({ data, status, statusText, headers, config, request });
    return data;
  } catch (ex) {
    console.log({ ex });

    return null;
  }
};

export const useMe = () => {
  const queryUser = useQuery(
    "me",
    async () => {
      const user = await getMe();
      return user;
    },
    {
      refetchInterval: 60 * 1000 * 30, // Refresh user status every 30 minutes.
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  // When we're loading data for the first time
  if (queryUser.data === undefined)
    return { queryUser, me: undefined, online: false, isAdmin: false };

  const me = queryUser.data ? queryUser.data : null;
  const online = me !== null && me !== undefined && me.id !== null && me.id !== 0;

  // Temporary until it's implemented in backend.
  // Used to hide admin acitons. (ATM used in HomeScreen.tsx, FilesScreen.tsx, FileListItem.tsx)
  const isAdmin = true;

  return { queryUser, me, online, isAdmin };
};

//export const UserService = { userUser }
