import React, { useContext, useState } from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import RootNavigator from "./RootNavigator";
import { StyleContext } from "../style/StyleSheet";
import { useNavigation } from "@react-navigation/native";
import { useAuthContext } from "../Authentication";
import { RootStackParamList } from "./navigation.types";
import MainTabNavigator from "./MainTabNavigator";

const Drawer = createDrawerNavigator();

export const DrawerNavigator = () => {
  const style = useContext(StyleContext);
  const { signOut } = useAuthContext();
  const navigation = useNavigation<RootStackParamList>();

  return (
    <Drawer.Navigator
      initialRouteName="MainDrawer"
      drawerContent={() => {
        return (
          <DrawerContentScrollView>
            <DrawerItem
              label="Hem"
              onPress={() => {
                (navigation as any).navigate("Home");
              }}
            />
            <DrawerItem
              label="Mitt konto"
              onPress={() => {
                (navigation as any).navigate("Settings");
              }}
            />
            <DrawerItem label="Administrera" onPress={() => {}} />
            <DrawerItem
              label="Logga ut"
              onPress={() => {
                signOut();
              }}
            />
          </DrawerContentScrollView>
        );
      }}
      screenOptions={{
        headerShown: false,
        drawerPosition: "right",
        drawerType: "front",
      }}
    >
      <Drawer.Screen name="MainDrawer" options={{ title: "Hem" }}>
        {() => {
          return <RootNavigator />;
        }}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};
