import { useTheme } from "@react-navigation/native";
import React, { useContext } from "react";
import { Text, Pressable, ViewStyle, TextStyle } from "react-native";
import { StyleContext } from "../style/StyleSheet";

type Props = {
  text: string;
  onPress?: () => void;
  style?: ViewStyle;
  textStyle?: TextStyle;
  type?: "fill" | "border" | "clear" | "text";
  color?: string;
  disabled?: boolean;
};

const Button = (props: Props) => {
  const style = useContext(StyleContext);
  const { type = "fill", color = style.colors.tintLight } = props;
  const { colors } = useTheme();
  const background = type == "fill" ? color : colors.background;
  const borderWidth = type == "border" ? 2 : 0;
  const textColor = type == "fill" ? colors.background : color;

  return (
    <Pressable
      style={[
        style.button,
        { backgroundColor: background, borderWidth: borderWidth, borderColor: color },
        type === "text" ? { shadowOpacity: 0, elevation: 0 } : null,
        props.style,
      ]}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      <Text style={[style.buttonText, { color: textColor }, props.textStyle]}>{props.text}</Text>
    </Pressable>
  );
};

export default Button;
