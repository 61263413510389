import React from "react";
import { View, Text, StyleProp, ViewStyle, Pressable } from "react-native";
import { Message } from "../models/Message";
import { StyleContext } from "../style/StyleSheet";

type Props = {
  data: Message;
  /** If the message is unread. */
  unread?: boolean;
  /** If the message is to this specific user. */
  personal?: boolean; // TODO: Maybe a slight differece in presentation?
  /** Style applied to the container. */
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  /** If the message card is presented and wrapped in a modal */
  isModal?: boolean;
};

const MessageCard = (props: Props) => {
  const style = React.useContext(StyleContext);
  const { created, subject, body, team } = props.data;
  const unreadStyle = props.unread ? style.unreadMessage : null;

  return (
    <Pressable
      onPress={props.onPress}
      style={[
        style.messageCardContainer,
        unreadStyle,
        props.isModal && { ...style.bottomModal, height: "60%" },
        props.style,
      ]}
    >
      <View style={style.cardHeader}>
        <Text style={[style.cardHeaderText, { fontSize: 16 }]}>{subject}</Text>
        <Text style={[style.cardHeaderText, { opacity: 0.6 }]}>{`${team.name}`}</Text>
      </View>

      {/* TODO: change to scrollview, in case text doesnt fit modal. */}
      <View style={[style.messageCardContent, { opacity: props.isModal ? 1 : 0.6 }]}>
        <Text style={props.isModal && { fontSize: 18 }}>{body}</Text>
      </View>
      <View style={[style.cardHeader, { paddingVertical: 5 }]}>
        <Text style={[style.cardHeaderText, { opacity: 0.6, textAlign: "right", fontSize: 12 }]}>
          {created
            ? created.toLocaleDateString() +
              ", " +
              created.toLocaleTimeString([], { timeStyle: "short" })
            : "unknown time"}
        </Text>
      </View>
    </Pressable>
  );
};

export default MessageCard;
