import { DefaultTheme, DarkTheme, Theme } from "@react-navigation/native";

/**
 * Creates a react-navigation light mode Theme object based on DefaultTheme.
 *
 * @param tintColor - An HTML color code used as tint for various elements. Usually brand color.
 * @returns An object that can be passed as a theme prop to a NavigationContainer.
 */
const createLightTheme = (tintColor: string): Theme => {
  return {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: tintColor,
      border: tintColor,
      background: "#fdfdfd",
    },
  };
};

// TODO: createDarkTheme to match OS setting. See https://reactnavigation.org/docs/themes#using-the-operating-system-preferences

export { createLightTheme };
