import React from "react";
import { useContext } from "react";
import { ImageBackground, StyleProp, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";
import { useAuthContext } from "../Authentication";
import { StyleContext } from "../style/StyleSheet";

type Props = {
  children?: React.ReactNode;
  height?: number | string;
  containerStyle?: StyleProp<ViewStyle>;
  renderChildren?: boolean;
};

const ScreenHeader = (props: Props) => {
  const { backgroundImage, colors } = useContext(StyleContext);
  const { renderChildren = true } = props;

  return (
    <View style={[{ width: "100%", marginBottom: 10 }, props.containerStyle]}>
      <View style={{ width: "100%", aspectRatio: 10, zIndex: -1 }}>
        <ImageBackground
          source={{ uri: backgroundImage }}
          resizeMode={"cover"}
          style={{ width: "100%" }}
        >
          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: props.height,
              marginBottom: 0,
            }}
          >
            {renderChildren && props.children}
          </View>

          <Svg
            height="100%"
            width="100%"
            style={{ position: "relative", top: 0 }}
            viewBox="0 0 1440 144"
          >
            <Path
              //fill={colors.tintVeryLight}
              fill={"rgba(0, 0, 0, 0.5)"}
              d="M0,128L60,112C120,96,240,64,360,58.7C480,53,600,75,720,96C840,117,960,139,1080,138.7C1200,139,1320,117,1380,106.7L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            />
          </Svg>
        </ImageBackground>
      </View>
    </View>
  );
};

export default ScreenHeader;
