import React from "react";
import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { DefaultTheme, Theme } from "@react-navigation/native";
import { TinyColor } from "@ctrl/tinycolor";

interface StyleInterface {
  colors: {
    tint: string;
    tintLight: string;
    tintDark: string;
    tintVeryLight: string;
  };
  backgroundImage: string;
  card: ViewStyle;
  container: ViewStyle;
  signInScreen: ViewStyle;
  logoSmall: ImageStyle;
  logoLarge: ImageStyle;
  textInput: TextStyle;
  signInInput: ViewStyle;
  button: ViewStyle;
  buttonText: TextStyle;
  bottomModal: ViewStyle;
  cardHeader: ViewStyle;
  cardHeaderText: TextStyle;
  messageCardContainer: ViewStyle;
  messageCardContent: ViewStyle;
  unreadMessage: ViewStyle;
  fileListItem: ViewStyle;
}

/**
 * Creates a react-native StyleSheet style reference based on the given Theme.
 *
 * @param theme The react-navigation Theme that the sheet will use for coloring elements.
 * @returns A react-native StyleSheet style reference.
 */
const createThemedStyleSheet = (theme: Theme = DefaultTheme): StyleInterface => {
  let border: ViewStyle = {
    borderColor: theme.colors.border,
    borderWidth: 1,
  };
  let shadow: ViewStyle = {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  };
  let card: ViewStyle = {
    borderRadius: 8,
    backgroundColor: theme.colors.background,
  };

  let primaryColor = new TinyColor(theme.colors.primary);
  let colors = {
    tint: primaryColor.toString(),
    tintDark: primaryColor.darken(25).toString(),
    tintLight: primaryColor.lighten(25).toString(),
    tintVeryLight: primaryColor.setAlpha(0.1).onBackground("fff").toString(),
  };
  let backgroundImage = "https://mik.earn.se/files/backgrounds/background.jpeg";

  let sheet = StyleSheet.create({
    container: {
      flex: 1,
      flexGrow: 1,
      alignItems: "stretch",
      justifyContent: "flex-start",
      backgroundColor: colors.tintVeryLight,
      //backgroundColor: 'transparent',
      color: theme.colors.text,
    },
    signInScreen: {
      backgroundColor: primaryColor.toString(),
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      ...shadow,
      ...card,
    },
    logoSmall: {
      width: 100,
      height: 50,
      resizeMode: "cover",
    },
    logoLarge: {
      width: 200,
      height: 200,
      resizeMode: "contain",
    },
    textInput: {
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.border,
      color: theme.colors.text,
      fontSize: 20,
      paddingVertical: 5,
      paddingHorizontal: 10,
      margin: 5,
      borderRadius: 4,
    },
    signInInput: {
      width: "80%",
      maxWidth: 300,
    },
    button: {
      ...shadow,
      borderRadius: 20,
      backgroundColor: colors.tintLight,
      paddingHorizontal: 20,
      paddingVertical: 10,
      margin: 5,
      alignItems: "center",
      justifyContent: "center",
    },
    buttonText: {
      color: "#fff",
      fontSize: 16,
    },
    bottomModal: {
      ...card,
      width: "100%",
      marginVertical: 0,
      marginHorizontal: 0,
    },
    cardHeader: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      flexShrink: 0,
      flexGrow: 0,
    },
    cardHeaderText: {
      color: colors.tint,
      fontWeight: "bold",
      fontSize: 14,
    },
    messageCardContainer: {
      ...shadow,
      ...card,
      height: 140,
      marginVertical: 10,
      marginHorizontal: 10,
    },
    messageCardContent: {
      borderTopWidth: 1,
      borderTopColor: "#ddd",
      overflow: "hidden",
      padding: 8,
      // color: theme.colors.text,
      flexGrow: 1,
      flex: 1,
    },
    unreadMessage: {
      ...border,
    },
    fileListItem: {
      ...shadow,
      ...card,
      flexDirection: "row",
      marginHorizontal: 10,
      marginVertical: 5,
      alignItems: "stretch",
      overflow: "hidden",
    },
  });
  return { colors, backgroundImage, ...sheet };
};

/** A context providing the current style sheet */
const StyleContext = React.createContext({} as StyleInterface);

export { createThemedStyleSheet, StyleContext, StyleInterface };
