import { StatusBar } from "expo-status-bar";
import React from "react";
import { Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { MainTabScreenProps } from "../navigation/navigation.types";
import { StyleContext } from "../style/StyleSheet";

const PersonalVideoScreen = ({}: MainTabScreenProps<"PersonalVideo">) => {
  const style = React.useContext(StyleContext);

  return (
    <SafeAreaView style={style.container}>
      <StatusBar style="light" />
    </SafeAreaView>
  );
};

export default PersonalVideoScreen;
