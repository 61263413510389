import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import {
  CompositeScreenProps,
  createNavigationContainerRef,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { DocumentFile } from "../types";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export const navigationRef = createNavigationContainerRef();

/** Export function that can be used outside the navigation container. */
export function navigate(
  screen: keyof RootStackParamList,
  params: NavigatorScreenParams<MainTabParamList>
) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(screen, params);
  }
}

/** The screens for the root stack navigator and their route.params. */
export type RootStackParamList = {
  MainTabs: NavigatorScreenParams<MainTabParamList> | undefined;
  Messages: undefined;
  FilePreview: {
    file: DocumentFile;
    download?: boolean; // Whether file should be downloaded before viewed.
  };
  MessageModal: { messageId: number };
  ComposeModal: undefined;
  DrawerNavigator: undefined;
  Settings: undefined;
};

/** The screens for the main tab navigation and their route.params. */
export type MainTabParamList = {
  /** Optional id specifying id of message to be initially showed. */
  Home: { id: number } | undefined;
  General: undefined;
  Personal: undefined;
  PersonalVideo: undefined;
  Settings: undefined;
  SettingsView: undefined;
};

/**
 * Route and navigation props for the screens in the root navigator.
 *
 * Usage: { route, navigation }: RootScreenProps<"FilePreview">
 */
export type RootScreenProps<RouteName extends keyof RootStackParamList> = StackScreenProps<
  RootStackParamList,
  RouteName
>;

/**
 * Route and navigation props for the screens in the main tabs navigator.
 * This is combined with the navigation prop for the root stack to allow routing from the tab
 * screens to the root stack screens (e.g. FilesScreen -> FilePreview).
 *
 * Usage: { route, navigation }: MainTabScreenProps<"Home">
 */
export type MainTabScreenProps<RouteName extends keyof MainTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<MainTabParamList, RouteName>,
  RootScreenProps<"MainTabs">
>;
