import { StatusBar } from "expo-status-bar";
import React from "react";
import { Text, View } from "react-native";
import { ScreenHeader, TeamLogo } from "../components";
import { MainTabScreenProps } from "../navigation/navigation.types";
import { StyleContext } from "../style/StyleSheet";

const PersonalScreen = ({}: MainTabScreenProps<"Personal">) => {
  const style = React.useContext(StyleContext);

  return (
    <View style={style.container}>
      <StatusBar style="light" />
      <ScreenHeader></ScreenHeader>
    </View>
  );
};

export default PersonalScreen;
