import React, { useEffect, useState } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Message } from "../../models/Message";
import { RootScreenProps } from "../../navigation/navigation.types";
import { useMessages } from "../../services/message.service";
import { StyleContext } from "../../style/StyleSheet";
import MessageCard from "../MessageCard";

const MessageModal = ({ route, navigation }: RootScreenProps<"MessageModal">) => {
  const style = React.useContext(StyleContext);
  const { top } = useSafeAreaInsets();
  const { messages, queryMessages } = useMessages();
  const [message, setMessage] = useState<Message>();
  const { messageId } = route.params;

  // Obtains the desired message based on passed message id.
  useEffect(() => {
    if (messages == null || queryMessages.isFetching) {
      return;
    }
    let found = messages.find((m) => m.id === messageId);
    if (found) {
      setMessage(found);
    } else {
      // TODO: maybe handle it better, like trying again and/or showing some error.
      navigation.goBack();
    }
  }, [messageId, messages]);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "flex-end",
        paddingTop: top,
      }}
    >
      <Pressable style={StyleSheet.absoluteFill} onPress={navigation.goBack} />
      {message && <MessageCard data={message} isModal />}
    </View>
  );
};

export default MessageModal;
