import decode from "jwt-decode";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Platform } from "react-native";
import { Storage } from "../services/storage.service";
import { backendEnvironment } from "./backend.service";

const expireInMinutes = async () => {
  const token = await getRefreshTokenAsync();
  if (!token) {
    return -1;
  }

  return 1;

  /*
  if (!token.exp) {
    return -1;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(token.exp);

  const exp = (date.valueOf() - new Date().valueOf()) / 1000 / 60;
  console.log('Refresh token expires in ' + Math.round(exp / 60 / 24) + ' days.');

  return exp;
  */
};

/**
 *
 * @param data { email, username, password }
 */
const postLogin = async (data: any) => {
  return await axios.post<string>(backendEnvironment.address() + "oauth/login", data, {
    withCredentials: true,
  });
};

const logout = async () => {
  Storage.deleteItem("refreshToken");
};

/**
 *
 * @param username
 * @param password
 * @param expoPushToken
 * @returns a promise wrapping the result óf the login attempt
 */
const login = async (
  username: string,
  password: string,
  expoPushToken: string
): Promise<{ refreshToken?: string; responseStatus?: number; error: Error | null }> => {
  return postLogin({
    email: username,
    password,
    expoPushToken,
  })
    .then(({ data, status }) => {
      const jwtToken: any = decode(data);
      if (jwtToken) {
        Storage.setItem("refreshToken", data);
        return { refreshToken: data, responseStatus: status, error: null };
      } else {
        return { responseStatus: status, error: new Error("Unable to decode JWTToken") };
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        return { responseStatus: error.response?.status, error: error };
      } else {
        return { error: error };
      }
    });

  /*
  if (error) {
    return { error };
  }
  if (!response) {
    return { error: new Error('Empty response') };
  }
  */
};

const getRefreshTokenAsync = async (): Promise<any | null> => {
  let token: string | null;
  token = await Storage.getItem("refreshToken");
  return token;
};

/**
 * Checks if a HttpOnly cookie exists.
 * @param name
 */
const doesHttpOnlyCookieExist = (name: string): boolean => {
  // No HTTP-cookies for native.
  if (Platform.OS !== "web") return false;

  var d = new Date();
  d.setTime(d.getTime() + 1000);
  var expires = "expires=" + d.toUTCString();

  if (document) {
    document.cookie = name + "=invalid;path=/;" + expires;
    if (document.cookie.indexOf(name + "=") === -1) {
      return true;
    }
  }
  return false;
};

/**
 * Returns X-Token-Refresh header if X-Token cookie is not set as HttpOnlyCookie
 * @param useRefreshToken should we force use refresh token?
 * @returns header object with or without refresh token
 */
const header = async (useRefreshToken?: boolean) => {
  const refreshToken = await getRefreshTokenAsync();
  //console.log({ refreshToken })
  if ((refreshToken && !doesHttpOnlyCookieExist("X-Token")) || (refreshToken && useRefreshToken)) {
    return { "X-Token-Refresh": refreshToken };
  } else {
    return {};
  }
};

/**
 * Exports Auth object
 */
export const Auth = { login, logout, getRefreshTokenAsync, expireInMinutes, header };
