import React from "react";
import { useState } from "react";
import { View, StyleSheet, Pressable, Text, KeyboardAvoidingView, Platform } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { RootScreenProps } from "../../navigation/navigation.types";
import { StyleContext } from "../../style/StyleSheet";
import Button from "../Button";
import { useHeaderHeight } from "@react-navigation/elements";
import { MessageService } from "../../services/message.service";
import { useMe } from "../../services/user.service";
import { useAuthContext } from "../../Authentication";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ComposeModal = ({ navigation }: RootScreenProps<"ComposeModal">) => {
  const style = React.useContext(StyleContext);
  const headerHeight = useHeaderHeight();
  const { top } = useSafeAreaInsets();
  const { activeTeam } = useAuthContext();
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  return (
    <KeyboardAvoidingView
      behavior={"padding"}
      enabled={Platform.OS === "ios"} // Wasn't needed on android (emulator).
      keyboardVerticalOffset={headerHeight}
      style={{
        flex: 1,
        justifyContent: "flex-end",
        paddingTop: top,
      }}
    >
      <Pressable style={StyleSheet.absoluteFill} onPress={navigation.goBack} />
      <View
        style={[
          style.bottomModal,
          {
            width: Platform.OS === "web" ? "50%" : "100%",
            padding: 16,
            flexBasis: 400,
            flexShrink: 1,
            maxHeight: "100%",
            backgroundColor: style.colors.tint,
          },
        ]}
      >
        <Text style={{ color: "#fff", fontSize: 18 }}>Rubrik</Text>
        <TextInput
          maxLength={99}
          placeholder="Ange rubrik "
          onChangeText={(text) => setSubject(text)}
          value={subject}
          style={[style.textInput]}
        />
        <Text style={{ color: "#fff", fontSize: 18 }}>Meddelande</Text>
        <TextInput
          multiline
          maxLength={3500}
          placeholder="Skriv meddelandet här"
          onChangeText={(text) => setBody(text)}
          value={body}
          textAlignVertical="top"
          style={[style.textInput, { flex: 1, flexShrink: 1 }]}
        />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Button
            style={{ width: "45%" }}
            text="Avbryt"
            onPress={navigation.goBack}
            color={style.colors.tintDark}
          />
          <Button
            style={{ width: "45%" }}
            text="Skicka"
            onPress={() => {
              if (activeTeam) {
                MessageService.sendMessage(activeTeam, subject, body);
              } else {
                // Shouldn't happen, I think.
                console.warn("Couldn't find user or user's team");
              }
              navigation.goBack();
            }}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ComposeModal;
