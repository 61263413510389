import React, { useState } from "react";
import { useEffect } from "react";
import { StyleSheet, ViewStyle, TextStyle } from "react-native";
import RNPickerSelect, { Item } from "react-native-picker-select";
import { Team } from "../models/Team";
import { useAuthContext } from "../Authentication";
import { StyleContext } from "../style/StyleSheet";
import { Ionicons } from "@expo/vector-icons";

type Props = {
  style?: TextStyle;
  containerStyle?: ViewStyle;
  /** Color of text border and icon. */
  color?: string;
};

/** A select picker for selecting the active team. Updates the auth context on selection change. */
const TeamPicker = (props: Props) => {
  const style = React.useContext(StyleContext);
  const { color = "#000" } = props;
  const { user, activeTeam, setActiveTeam } = useAuthContext();
  const [options, setOptions] = useState<Item[]>([]);

  // Creates an array with items for the teams the user belongs in.
  // The items are used to populate the picker component with options.
  useEffect(() => {
    let teams = user?.teams ?? [];

    setOptions(
      teams.map((team) => ({
        label: team.name,
        value: team,
        key: team.id?.toString(),
      }))
    );
  }, []);

  return (
    <RNPickerSelect
      onValueChange={(value) => {
        if (value !== activeTeam) {
          setActiveTeam(value);
        }
      }}
      //Icon={() => <Ionicons name="chevron-down" color={color} size={20} />}
      value={activeTeam}
      useNativeAndroidPickerStyle={false}
      items={options}
      placeholder={{}}
      style={{
        inputIOS: {
          ...style.textInput,
          ...pickerSelectStyles.input,
          color: color,
          borderColor: color,
          ...props.style,
        },
        inputAndroid: {
          ...style.textInput,
          ...pickerSelectStyles.input,
          color: color,
          borderColor: color,
          ...props.style,
        },
        inputIOSContainer: props.containerStyle,
        inputAndroidContainer: props.containerStyle,
        iconContainer: {
          height: "20%",
          right: 14,
          top: "30%",
          justifyContent: "center",
        },
      }}
    />
  );
};

const pickerSelectStyles = StyleSheet.create({
  input: {
    borderBottomWidth: 1,
    paddingRight: 40,
    width: "50%",
    // flexGrow: 1,
  },
});

export default TeamPicker;
