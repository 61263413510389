import React from "react";
import { Team } from "./models/Team";
import { User } from "./models/User";
import { Auth } from "./services/auth.service";
import { MessageService } from "./services/message.service";
import { useMe } from "./services/user.service";

type AuthContextType = {
  user?: User | null;
  activeTeam: Team | null;
  setActiveTeam: React.Dispatch<React.SetStateAction<Team | null>>;

  /**
   * Attempts to sign in the user.
   *
   * @param username
   * @param password
   */
  signIn: (
    username: string,
    password: string
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  /** Signs the user out. */
  signOut: () => void;
};

/** Authentication context object for exposing methods for signing in and signing out. */
const AuthContext = React.createContext<AuthContextType>(undefined!);

/** AuthProvider */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { queryUser, me } = useMe();
  const [activeTeam, setActiveTeam] = React.useState<Team | null>(null);

  const signIn = async (username: string, password: string) => {
    const token = await MessageService.registerForPushNotificationsAsync();
    const result = await Auth.login(username, password, token ? token : "");

    if (result.error !== null || !result.refreshToken) {
      // Message to show user
      // TODO: add messages to more types of error status codes and
      // consider setting the message on some global storage (AsyncStorage?)
      // instead of returning.
      let message = "Login failed. Please try again or check your connection.";
      if (result.responseStatus === 401) {
        message = "Invalid email or password";
      } else {
        console.log(result.error);
      }
      return { success: false, errorMessage: message };
    }
    await queryUser.refetch();
    return { success: true };
  };

  const signOut = async () => {
    await Auth.logout();
    await queryUser.refetch();
    setActiveTeam(null);
  };

  return (
    <AuthContext.Provider value={{ user: me, activeTeam, setActiveTeam, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

/** AuthContext */
export const useAuthContext = () => React.useContext(AuthContext);
