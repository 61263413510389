// @ts-ignore
import { REACT_APP_API_URL, REACT_APP_API_VERSION } from "@env";

/**
 * Environment stuff
 */
export var backendEnvironment = {
  backend: "https://team.earn.se",
  version: "1",
  address: (): string => {
    return backendEnvironment.backend + "/api/" + backendEnvironment.version + "/";
  },
};
