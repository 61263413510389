import React from "react";
import { View, Text, ViewStyle, TextStyle } from "react-native";

interface Props {
  style?: ViewStyle;
  textStyle?: TextStyle;
  children?: React.ReactNode;
}

const TextHeading = (props: Props) => {
  return (
    <View style={[{ marginHorizontal: 20, marginVertical: 30 }, props.style]}>
      <Text style={[{ fontSize: 24, fontWeight: "bold", color: "#606060" }, props.textStyle]}>
        {props.children}
      </Text>
    </View>
  );
};

export default TextHeading;
