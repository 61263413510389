import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useContext } from "react";
import { View, Text, Pressable, Alert } from "react-native";
import { useMe } from "../services/user.service";
import { StyleContext } from "../style/StyleSheet";
import { DocumentFile } from "../types";

type Props = {
  file: DocumentFile;
};

const FileListItem = (props: Props) => {
  const style = useContext(StyleContext);
  const navigation = useNavigation();
  const { file } = props;
  const { isAdmin } = useMe();

  return (
    <View style={style.fileListItem}>
      {isAdmin && (
        <Pressable
          style={{
            backgroundColor: "darkred",
            justifyContent: "center",
            paddingHorizontal: 10,
          }}
          onPress={() => {
            // TODO: actually delete file and only show this button to admins.
            Alert.alert(
              "Delete file",
              `Are you sure you want to delete ${file.name}?\nThis cannot me undone.`,
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Calcelled deletion."),
                  style: "cancel",
                },
                {
                  text: "Confirm",
                  onPress: () => console.log("Removing:", file.name),
                  style: "destructive",
                },
              ]
            );
          }}
        >
          <Ionicons name="close" style={{}} size={24} color={style.colors.tintVeryLight} />
        </Pressable>
      )}
      <Pressable
        onPress={() => navigation.navigate("FilePreview", { file: file })}
        style={[
          {
            paddingVertical: 10,
            paddingHorizontal: 15,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          },
        ]}
      >
        <Ionicons name="document" style={{ flex: 0 }} size={24} color={style.colors.tintDark} />
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text style={{ fontSize: 18, color: style.colors.tint }}>{file.name}</Text>
          <Text style={{ fontSize: 14, color: style.colors.tintLight }}>Some metadata</Text>
        </View>
        <Ionicons
          name="chevron-forward"
          style={{ flex: 0 }}
          size={24}
          color={style.colors.tintDark}
        />
      </Pressable>
    </View>
  );
};

export default FileListItem;
