import { StatusBar } from "expo-status-bar";
import React, { useContext } from "react";
import { Text, View } from "react-native";
import { MainTabParamList, MainTabScreenProps } from "../navigation/navigation.types";
import { useAuthContext } from "../Authentication";
import { Team } from "../models/Team";
import { MessageService } from "../services/message.service";
import { Button, ScreenHeader, Toast, UserInfoCard } from "../components";
import { ScrollView } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StyleContext } from "../style/StyleSheet";
import { useNavigation } from "@react-navigation/core";

type IconName = React.ComponentProps<typeof Ionicons>["name"];
const Tab = createBottomTabNavigator<MainTabParamList>();

const SettingsScreen = ({}: MainTabScreenProps<"Settings">) => {
  const style = React.useContext(StyleContext);
  const { user, signOut } = useAuthContext();
  const navigator = useNavigation();

  return (
    <ScrollView contentContainerStyle={style.container}>
      <View style={style.container}>
        <StatusBar style="light" />
        <ScreenHeader renderChildren={false}></ScreenHeader>
        <UserInfoCard />
        <Button
          text="Tillbaka"
          style={{ alignSelf: "center" }}
          onPress={() => navigator.goBack()}
        />
      </View>
    </ScrollView>
  );
};

export default SettingsScreen;
