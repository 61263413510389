import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useAuthContext } from "../Authentication";
import { MessageCard } from "../components";
import { Message } from "../models/Message";
import { RootScreenProps } from "../navigation/navigation.types";
import { useMessages } from "../services/message.service";
import { StyleContext } from "../style/StyleSheet";
import { filterMessages } from "../utils/filterMessages";

const MessagesScreen = ({ navigation }: RootScreenProps<"Messages">) => {
  const style = useContext(StyleContext);
  const { messages } = useMessages();
  const { activeTeam } = useAuthContext();
  const [filteredMessages, setFilteredMessages] = useState<Message[]>([]);

  /** Filters the messages to the active team's only */
  useEffect(() => {
    if (activeTeam && messages) {
      console.log("[MessagesScreen.tsx] Filtering messages for team", activeTeam.name);
      setFilteredMessages(filterMessages(messages, activeTeam));
    }
  }, [activeTeam, messages]);

  // Maybe check if item.id exist in some persistant storage for read messages, and if not, set unread to true.
  const renderItem = ({ item }: { item: Message }) => (
    <MessageCard
      data={item}
      unread={false}
      onPress={() => navigation.navigate("MessageModal", { messageId: item.id! })}
    />
  );

  return (
    <View style={style.container}>
      <StatusBar style="light" />
      <FlatList
        contentContainerStyle={{
          paddingVertical: 10,
        }}
        data={filteredMessages}
        extraData={filteredMessages}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
};

export default MessagesScreen;
