import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { useContext } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useAuthContext } from "../Authentication";
import { TeamLogo } from "../components";
import {
  HomeScreen,
  GeneralScreen,
  SettingsScreen,
  PersonalScreen,
  PersonalVideoScreen,
} from "../screens";
import { StyleContext } from "../style/StyleSheet";
import { MainTabParamList, RootScreenProps } from "./navigation.types";

type IconName = React.ComponentProps<typeof Ionicons>["name"];

const Tab = createBottomTabNavigator<MainTabParamList>();

const MainTabNavigator = ({}: RootScreenProps<"MainTabs">) => {
  const { colors } = useContext(StyleContext);
  const { top } = useSafeAreaInsets();
  const { activeTeam } = useAuthContext();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={({ route }) => ({
        // Centralizing the icon configuration for convenience.
        tabBarIcon: ({ focused, color, size }) => {
          // The 'color' param is the tab's activeTintColor or
          // inactiveTintColor prop, depending on its state.
          let iconName: IconName = "warning";

          if (route.name === "Home") {
            iconName = focused ? "home" : "home-outline";
          } else if (route.name === "Personal") {
            iconName = focused ? "document-text" : "document-text-outline";
          } else if (route.name === "General") {
            iconName = focused ? "calendar" : "calendar-outline";
          } else if (route.name === "PersonalVideo") {
            iconName = focused ? "film" : "film-outline";
            //} else if (route.name === "Files") {
            //  iconName = focused ? "document-text" : "document-text-outline";
          } else if (route.name === "Settings") {
            iconName = focused ? "settings" : "settings-outline";
          }

          return <Ionicons name={iconName} size={size} color={color} />;
        },
        headerShown: false,
      })}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "Hem",
        }}
      />
      <Tab.Screen
        name="Personal"
        component={PersonalScreen}
        options={{
          title: "Personliga Dok.",
        }}
      />
      <Tab.Screen
        name="PersonalVideo"
        component={PersonalVideoScreen}
        options={{
          title: "Video",
        }}
      />
      <Tab.Screen
        name="General"
        component={GeneralScreen}
        options={{
          title: "Klubbinfo",
        }}
      />
    </Tab.Navigator>
  );
};

export default MainTabNavigator;
