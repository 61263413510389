import React from "react";
import { Text } from "react-native";
import { RootScreenProps } from "../navigation/navigation.types";
import { WebView } from "react-native-webview";
import * as FileSystem from "expo-file-system";
import PDFReader from "rn-pdf-reader-js";
import { SafeAreaView } from "react-native-safe-area-context";

const WebViewModal = ({ url }: { url: string }) => {
  const [renderedOnce, setRenderedOnce] = React.useState(false);

  return (
    <WebView
      style={{ flex: 1 }}
      source={renderedOnce ? { uri: url } : undefined}
      allowFileAccess={true}
      originWhitelist={["file://", "https://", "http://"]}
      // Prevents ERR_ACCESS_DENIED on andoid in some cases.
      // See: https://github.com/react-native-webview/react-native-webview/issues/656
      onLoad={() => setRenderedOnce(true)}
    />
  );
};

const FilePreviewScreen = ({ route }: RootScreenProps<"FilePreview">) => {
  // TODO: make routeprops instead (https://reactnavigation.org/docs/typescript)
  const { file, download = false } = route.params;
  const { name, type } = file;
  const [url, setUrl] = React.useState<string | null>(null);

  // Downloads the file and uses local url if desired.
  React.useEffect(() => {
    let isMounted = true;
    console.log("URL: ", file.url);
    if (!download) {
      setUrl(file.url);
    } else {
      FileSystem.downloadAsync(file.url, FileSystem.cacheDirectory + name)
        .then(({ uri }) => {
          if (isMounted) setUrl(uri);
          console.log("Finished downloading to ", uri);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // Cleanup function when unmounting.
    return () => {
      // Prevents url state change after unmount.
      isMounted = false;
    };
  }, [file.url]);

  // TODO: use WebView for more types on iOS (pdf, docx). PDFReader not needed on iOS.
  if (!url) {
    return (
      <SafeAreaView>
        <Text>Loading file...</Text>
      </SafeAreaView>
    );
  } else if (type.startsWith("image")) {
    return <WebViewModal url={url} />;
  } else if (type === "application/pdf") {
    // WebView for pdf files didn't work on andoid (caused the app to crash).
    return (
      <PDFReader
        source={{
          uri: url,
        }}
        withPinchZoom={true}
        withScroll={true}
      />
    );
  } else {
    // TODO: Share the file (url) using expo-sharing or similar?
    return (
      <SafeAreaView>
        <Text>Unsupported filetype</Text>
      </SafeAreaView>
    );
  }
};

export default FilePreviewScreen;
