import { StatusBar } from "expo-status-bar";
import React from "react";
import { Image, KeyboardAvoidingView, Platform, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { StyleContext } from "../style/StyleSheet";
import { useAuthContext } from "../Authentication";
import { useMe } from "../services/user.service";
import { Button, TextInput } from "../components";
import { TextInputRef } from "../components/TextInput";

const SignInScreen = () => {
  const style = React.useContext(StyleContext);
  const authContext = useAuthContext();
  const [username, setUsername] = React.useState("admin");
  const [password, setPassword] = React.useState("admin");
  const [status, setStatus] = React.useState("");
  const { online } = useMe();

  // Don't display this page if we're already online
  // Fix for js error sometimes popping up
  if (online) return <></>;

  // Reference for the password field. Used by username field to pass focus.
  const passInputRef = React.useRef<TextInputRef>(null!);

  return (
    <KeyboardAvoidingView
      behavior={"padding"}
      enabled={Platform.OS === "ios"}
      style={[style.container, style.signInScreen]}
    >
      <StatusBar style="light" />
      <Image
        source={require("../../assets/icon.png")}
        style={[style.logoLarge, { marginBottom: 30 }]}
      />
      <View style={[style.card, style.signInInput]}>
        <Text style={{ fontSize: 16, color: "darkred" }}>{status}</Text>
      </View>
      <TextInput
        placeholder="Användarnamn / E-post"
        value={username}
        onChangeText={setUsername}
        autoCorrect={false}
        returnKeyType="next"
        textContentType="username"
        autoCapitalize="none"
        style={[style.textInput, style.signInInput]}
        onSubmitEditing={() => passInputRef.current.focus()}
        blurOnSubmit={false}
      />
      <TextInput
        placeholder="Lösenord"
        value={password}
        onChangeText={setPassword}
        autoCorrect={false}
        returnKeyType="done"
        textContentType="password"
        autoCapitalize="none"
        secureTextEntry
        style={[style.textInput, style.signInInput]}
        ref={passInputRef}
      />
      <Button
        onPress={async () => {
          authContext.signIn(username, password).then(({ success, errorMessage }) => {
            if (!success && errorMessage) {
              setStatus(errorMessage);
            }
          });
        }}
        style={style.signInInput}
        text="Logga in"
      />
    </KeyboardAvoidingView>
  );
};

export default SignInScreen;
