import { StatusBar } from "expo-status-bar";
import React from "react";
import { View } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { StyleContext } from "../style/StyleSheet";
import { MainTabScreenProps } from "../navigation/navigation.types";
import { DocumentFile } from "../types";
import { Button, FileListItem, ScreenHeader } from "../components";
import { FlatList } from "react-native-gesture-handler";
import { useMe } from "../services/user.service";

// Temporary for testing
const testFiles: DocumentFile[] = [
  {
    url: "https://via.placeholder.com/500/09f/fff.png",
    name: "fff.png",
    type: "image/png",
  },
  {
    url: "https://classics.berkeley.edu/sites/default/files/2020-01/sample.pdf",
    name: "sample.pdf",
    type: "application/pdf",
  },
  {
    url: "https://filesamples.com/samples/document/docx/sample4.docx",
    name: "sample4.docx",
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
];

// TODO: use real remote files and hide upload button from non-admins

const GeneralScreen = ({}: MainTabScreenProps<"General">) => {
  const style = React.useContext(StyleContext);
  const files = [...testFiles, ...testFiles, ...testFiles, ...testFiles];
  const { isAdmin } = useMe();

  const renderItem = ({ item }: { item: DocumentFile }) => <FileListItem file={item} />;

  return (
    <View style={style.container}>
      <StatusBar style="light" />
      <FlatList
        ListHeaderComponent={
          <ScreenHeader renderChildren={isAdmin}>
            <Button
              style={{ alignSelf: "center" }}
              color={style.colors.tint}
              text="Ladda upp"
              onPress={() => {
                DocumentPicker.getDocumentAsync({ copyToCacheDirectory: false }).then((result) => {
                  if (result.type === "success") {
                    // TODO: send file to back-end
                    console.log("Uploading (not actually)", result.name);
                    console.log(" - Uri:", result.uri);
                    console.log(" - Size:", result.size);
                    console.log(" - Last modified:", result.lastModified);
                  } else {
                    console.log("Uploading cancelled");
                  }
                });
              }}
            />
          </ScreenHeader>
        }
        contentContainerStyle={{
          paddingBottom: 10,
        }}
        data={files}
        // extraData={files}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};

export default GeneralScreen;
