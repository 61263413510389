import { getFocusedRouteNameFromRoute, RouteProp, useNavigation } from "@react-navigation/native";
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack";
import React, { Fragment, useContext, useState } from "react";
import * as Notifications from "expo-notifications";
import { ComposeModal, MessageModal, TeamPicker, Toast } from "../components";
import { FilePreviewScreen, MessagesScreen, SettingsScreen } from "../screens";
import { StyleContext } from "../style/StyleSheet";
import MainTabNavigator from "./MainTabNavigator";
import { RootStackParamList } from "./navigation.types";
import { Image, ImageBackground, View } from "react-native";
import { Button } from "react-native-elements";
import { Entypo } from "@expo/vector-icons";
import { DrawerNavigator } from "./DrawerNavigator";
const RootStack = createStackNavigator<RootStackParamList>();

function getHeaderTitle(route: RouteProp<RootStackParamList>) {
  return getFocusedRouteNameFromRoute(route) ?? "";
}

/**
 * A stack navigator used as the root navigator for the app.
 *
 * This is used to make screens and modals (e.g. FilePreview) render on top of
 * the tab navigation instead of inside, which they would if the stack was to
 * be defined inside the screens (e.g. inside FilesScreen).
 *
 * This stack contains the main tab navigation as initial screen and other screens and modals
 * that should be rendered over the tab navigation.
 */
const RootNavigator = () => {
  const style = useContext(StyleContext);
  const colors = style.colors;
  const navigation = useNavigation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  // Listens for notifications and shows a toast if the app is foregrounded.
  React.useEffect(() => {
    const subscription = Notifications.addNotificationReceivedListener((notification) => {
      const messageID =
        typeof notification.request.content.data.messageID === "number"
          ? notification.request.content.data.messageID
          : 0;
      const title = notification.request.content.title ?? "";
      const body = notification.request.content.body ?? "empty body";

      Toast.show({
        type: "message",
        props: { title: title, body: body, id: messageID },
      });
    });
    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <RootStack.Navigator
      initialRouteName={"MainTabs"}
      screenOptions={{
        headerStyle: {
          backgroundColor: colors.tintDark,
          elevation: 0,
          shadowOpacity: 0,
        },
        headerTintColor: "#fff",
        headerTitleAlign: "left",
        headerBackTitleVisible: false,
        headerMode: "screen",
        headerLeft: () => {
          return (
            <View style={{ flexDirection: "row", marginTop: 0 }}>
              <TeamPicker
                color={colors.tintLight}
                style={{
                  backgroundColor: "transparent",
                  fontSize: 16,
                }}
              />
            </View>
          );
        },
        /*
          // Example with logo to the left?          
          return (
            <View style={{ flexDirection: "row", marginTop: 0 }}>
              <View style={{ flex: 1, width: 120, marginTop: 0, padding: 0 }}>
                <Image
                  source={require("../../assets/icon.png")}
                  style={[style.logoSmall, { marginLeft: 10, marginRight: 50 }]}
                />
              </View>
              <TeamPicker
                color={colors.tintLight}
                style={{
                  backgroundColor: "transparent",
                  fontSize: 16,
                  marginLeft: 120,
                }}
              />
            </View>
          );
        },
        */
        headerRight: () => {
          return (
            <Button
              type="clear"
              icon={<Entypo name="cog" size={24} color="white" style={{ marginRight: 10 }} />}
              onPress={() => {
                // Type any to avoid warnings in compiler
                (navigation as any).openDrawer();
              }}
            />
          );
        },
        headerShown: true,
      }}
    >
      <RootStack.Screen
        name="MainTabs"
        component={MainTabNavigator}
        options={({ route }) => ({
          headerTitle: "",
        })}
      />
      <RootStack.Screen name="Messages" component={MessagesScreen} />
      <RootStack.Screen
        name="MessageModal"
        component={MessageModal}
        options={{
          headerShown: false,
          presentation: "transparentModal",
          cardOverlayEnabled: true,
          ...TransitionPresets.ModalTransition,
        }}
      />
      <RootStack.Screen
        name="ComposeModal"
        component={ComposeModal}
        options={{
          headerShown: false,
          presentation: "transparentModal",
          cardOverlayEnabled: true,
          ...TransitionPresets.ModalTransition,
        }}
      />
      <RootStack.Screen name="FilePreview" component={FilePreviewScreen} />
      <RootStack.Screen name="Settings" options={{ headerTitle: "" }} component={SettingsScreen} />
    </RootStack.Navigator>
  );
};

export default RootNavigator;
