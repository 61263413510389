import React from "react";
import ToastComponent, {
  BaseToast,
  ErrorToast,
  SuccessToast,
  InfoToast,
  BaseToastProps,
  ToastShowOptions,
} from "react-native-toast-message";
import { navigate } from "../navigation/navigation.types";
import { StyleInterface, StyleContext } from "../style/StyleSheet";

/** Configuration for the components of the different toast types */
const toastConfig = (styleSheet: StyleInterface) => ({
  test_type: (props: BaseToastProps) => (
    <BaseToast {...props} style={{ backgroundColor: "green" }} />
  ),
  message: ({
    props,
    text1,
    text2,
    ...rest
  }: { props: { title: string; body: string; id: number } } & BaseToastProps) => {
    return (
      <BaseToast
        {...rest}
        text1={props.title ?? text1}
        text2={props.body ?? text2}
        onPress={() => {
          hide();
          navigate("MainTabs", {
            screen: "Home",
            params: { id: props.id },
          });
        }}
        style={{ padding: 10, borderLeftColor: styleSheet.colors.tint }}
      />
    );
  },
});

type Props = {};

/** The toast component */
const Toast = (props: Props) => {
  const style = React.useContext(StyleContext);
  const [config, setConfig] = React.useState<ReturnType<typeof toastConfig>>();

  React.useEffect(() => {
    setConfig(toastConfig(style));
  }, [style]);

  return (
    <ToastComponent
      style={{ borderLeftColor: style.colors.tint }}
      config={config}
      ref={(ref) => ToastComponent.setRef(ref)}
    />
  );
};

/** Extended ToastShowOptions to allow typechecking toast type name. */
interface ShowOptions extends ToastShowOptions {
  type: "error" | "info" | "success" | keyof ReturnType<typeof toastConfig>;
}

const show = (options: ShowOptions) => ToastComponent.show(options);
const hide = ToastComponent.hide;

export { Toast, show, hide };
