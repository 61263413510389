import React, { useContext, useEffect, useState } from "react";
import { MainTabScreenProps } from "../navigation/navigation.types";
import { useMessages } from "../services/message.service";
import { ImageBackground, RefreshControl, ToastAndroid, View } from "react-native";
import { StyleContext } from "../style/StyleSheet";
import { Button, MessageCard, ScreenHeader, TeamLogo, TextHeading, Toast } from "../components";
import { StatusBar } from "expo-status-bar";
import { useAuthContext } from "../Authentication";
import { Message } from "../models/Message";
import { filterMessages } from "../utils/filterMessages";
import { useMe } from "../services/user.service";
import { ScrollView } from "react-native-gesture-handler";

const HomeScreen = ({ navigation, route }: MainTabScreenProps<"Home">) => {
  const style = useContext(StyleContext);
  const { messages } = useMessages();
  const { activeTeam } = useAuthContext();
  const [filteredMessages, setFilteredMessages] = useState<Message[]>([]);
  const id = route.params?.id;
  const { isAdmin } = useMe();
  const [refreshing, setRefreshing] = React.useState(false);
  /** Filters the messages to the active team's only */
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    Toast.show({
      type: "message",
      props: { title: "Uppdaterar data", body: "Ser om det finns något nytt" },
    });
    setRefreshing(false);
  }, [refreshing]);

  useEffect(() => {
    if (activeTeam && messages) {
      console.log("[HomeScreen.tsx] Filtering messages for team", activeTeam.name);
      setFilteredMessages(filterMessages(messages, activeTeam, 3));
    }
  }, [activeTeam, messages]);
  /** Opens a message modal if a message id was passed as a route param */
  useEffect(() => {
    if (id) {
      navigation.navigate("MessageModal", { messageId: id });
    }
  }, [id]);

  return (
    <ScrollView
      contentContainerStyle={style.container}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
    >
      <View style={style.container}>
        <StatusBar style="light" />
        {!isAdmin && (
          <ScreenHeader>
            <Button
              style={{ alignSelf: "center" }}
              text="Nytt meddelande"
              type="fill"
              color={style.colors.tint}
              onPress={() => navigation.navigate("ComposeModal")}
            />
          </ScreenHeader>
        )}
        {isAdmin && (
          <ScreenHeader>
            <Button
              style={{ alignSelf: "center" }}
              text="Kontakta klubb"
              type="fill"
              color={style.colors.tint}
              onPress={() => sendContactInfoAsync()}
            />
          </ScreenHeader>
        )}
        {filteredMessages[0] && (
          <MessageCard
            data={filteredMessages[0]}
            onPress={() =>
              navigation.navigate("MessageModal", { messageId: filteredMessages[0].id! })
            }
          />
        )}
        {filteredMessages[1] && (
          <View style={{ flexDirection: "row" }}>
            <MessageCard
              style={{ flex: 1, flexGrow: 1 }}
              data={filteredMessages[1]}
              onPress={() =>
                navigation.navigate("MessageModal", { messageId: filteredMessages[1].id! })
              }
            />
            {filteredMessages[2] && (
              <MessageCard
                style={{ flex: 1, flexGrow: 1 }}
                data={filteredMessages[2]}
                onPress={() =>
                  navigation.navigate("MessageModal", { messageId: filteredMessages[2].id! })
                }
              />
            )}
          </View>
        )}
        <Button
          text="Alla meddelanden"
          style={{ alignSelf: "center" }}
          onPress={() => navigation.navigate("Messages")}
        />
      </View>
    </ScrollView>
  );
};

export default HomeScreen;
function sendContactInfoAsync(): void {
  console.log("club is contacted");
}
