import { Message } from "../models/Message";
import { Team } from "../models/Team";

/**
 * Filters an array of messages.
 *
 * @param messages the message array to be filtered
 * @param team the team to only include messages from
 * @param quantity the quantity of messages to use, starting from the newest
 * @param omitNullId if messages without an id should be omitted
 * @returns the filtered array
 */
export const filterMessages = (
  messages: Message[],
  team?: Team,
  quantity?: number,
  omitNullId: boolean = true
) => {
  let filtered = messages;
  if (team) {
    filtered = filtered.filter(
      (message) => message.team.id === team.id && (omitNullId || message.id)
    );
  }
  filtered = filtered.slice(0, quantity);
  return filtered;
};
